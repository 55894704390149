import React from "react";
import "../assets/Style/omoss.css";
import { jsPDF } from "jspdf";
import { pizzaKategorier, gruppNamn } from "../components/PizzaMenu";

import omOssBild from "../assets/images/omoss/omOss.jpg";
import omOssBg from "../assets/images/omOssBg/omOssBg.jpg";
import diplom from "../assets/images/omoss/diplom.jpg";
import diplom1 from "../assets/images/omoss/diplom1.jpg";

const OmOss = () => {
  const generateMenuPDF = () => {
    const doc = new jsPDF();
    let yOffset = 20;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const contentWidth = pageWidth - 2 * margin;

    const addDarkBackground = () => {
      doc.setFillColor(20, 20, 20);
      doc.rect(0, 0, pageWidth, pageHeight, "F");
    };

    const capitalizeFirstLetter = (string) => {
      return string
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    addDarkBackground();

    doc.setFontSize(24);
    doc.setTextColor(255, 215, 0);
    doc.text("Pizzeria Istra Meny", pageWidth / 2, yOffset, {
      align: "center",
    });
    yOffset += 10;

    // Lägg till telefonnummer
    doc.setFontSize(14);
    doc.setTextColor(200, 200, 200);
    doc.text("Tel: 042-28 27 94", pageWidth / 2, yOffset, {
      align: "center",
    });
    yOffset += 15;

    gruppNamn.forEach((kategori) => {
      if (yOffset > 270) {
        doc.addPage();
        addDarkBackground();
        yOffset = 20;
      }

      doc.setFontSize(20);
      doc.setTextColor(255, 215, 0);
      doc.text(capitalizeFirstLetter(kategori), pageWidth / 2, yOffset, {
        align: "center",
      });
      yOffset += 10;

      pizzaKategorier[kategori].forEach((pizza, index) => {
        if (yOffset > 270) {
          doc.addPage();
          addDarkBackground();
          yOffset = 20;
        }

        doc.setFontSize(16);
        doc.setTextColor(255, 215, 0);
        doc.text(
          capitalizeFirstLetter(pizza.namn || "Namn Saknas"),
          margin,
          yOffset
        );
        yOffset += 5;

        if (pizza.ingredienser) {
          doc.setFontSize(12);
          doc.setTextColor(200, 200, 200);
          const ingrediensText =
            typeof pizza.ingredienser === "string"
              ? pizza.ingredienser
              : "Ingredienser saknas";
          const splitIngredients = doc.splitTextToSize(
            ingrediensText,
            contentWidth - 20
          );
          doc.text(splitIngredients, margin + 5, yOffset);
          yOffset += splitIngredients.length * 5;
        }

        doc.setFontSize(14);
        doc.setTextColor(255, 215, 0);
        const prisText = `${pizza.pris || "Pris saknas"} kr${
          pizza.familjepizza ? ` / Familjepizza: ${pizza.familjepizza} kr` : ""
        }`;
        doc.text(prisText, pageWidth - margin, yOffset, { align: "right" });
        yOffset += 10;

        if (index < pizzaKategorier[kategori].length - 1) {
          doc.setDrawColor(255, 215, 0);
          doc.line(
            pageWidth / 2 - 15,
            yOffset - 2,
            pageWidth / 2 + 15,
            yOffset - 2
          );
          yOffset += 5;
        }
      });

      yOffset += 15;
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor(150);
      doc.text(`Sida ${i} av ${pageCount}`, pageWidth / 2, 290, {
        align: "center",
      });
    }

    doc.save("pizzeria-istra-meny.pdf");
  };

  return (
    <div className="om-oss-container">
      <img src={omOssBg} alt="Om Oss Page Background" className="om-oss-bg" />
      <h2>Om Oss</h2>
      <div className="om-oss-innehåll">
        <h3>
          Välkommen till Pizzeria Istra, din lokala pizzeria där allt bakas med
          100% passion och ännu mer kärlek.
        </h3>
        <img className="om-oss-bild" src={omOssBild} alt="Om Oss" />
      </div>
      <div className="extra-info-grid">
        <div className="extra-info-item">
          <h3>Kvalité</h3>
          <p>
            Vi har ett stort utbud av pizzor för alla smaker. Våra kunder ska
            alltid kunna lita på att vi levererar den bästa kvalitén på våra
            pizzor. Därför använder vi endast de färskaste råvarorna och de
            bästa recepten.
          </p>
        </div>
        <div className="extra-info-item">
          <h3>Vårt koncept</h3>
          <p>
            Vårt koncept är att alltid servera dig den godaste pizzan. Vi
            strävar efter att ge dig den bästa upplevelsen - både när det gäller
            smak och service. Vårt team är dedikerat till att överträffa dina
            förväntningar och vara din go-to pizzeria för alla tillfällen.
          </p>
        </div>
        <div className="extra-info-item">
          <h3>QR Meny</h3>
          <p>
            Ladda ner vår digitala meny genom att klicka på knappen nedan eller
            skanna QR-koden. Du kan snabbt och enkelt se vårt utbud och beställa
            online. Glöm inte att följa oss på sociala medier för de senaste
            erbjudandena och nyheterna!
          </p>
          <button onClick={generateMenuPDF}>Ladda ner meny som PDF</button>
          {/* Här kan du lägga till QR-koden om du har en */}
        </div>
      </div>
      <div className="kontakt-och-diplom">
        <div className="kontaktuppgifter">
          <h3>Kontakta oss</h3>
          <p>
            Telefon: <a href="tel:042-28 27 94">042-28 27 94</a>/
            <a href="tel:070-894 33 22">070-894 33 22</a>
          </p>
          <p>
            Adress:{" "}
            <a
              href="https://www.google.com/maps/dir/?api=1&destination=Elinebergsvägen+14,+256+59+Helsingborg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Elinebergsvägen 14, 256 59 Helsingborg
            </a>
          </p>
          <p>Dommaregatan, vid Ramlösarondellen/Elinebergskiosken</p>
          <p>Öppettider: Mån-Sön 10-22</p>
        </div>
        <div className="diplom-container">
          <img src={diplom} alt="Diplom" className="diplom-image" />
          <img src={diplom1} alt="Diplom 1" className="diplom-image" />
        </div>
      </div>
    </div>
  );
};

export default OmOss;
