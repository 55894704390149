import React from "react";
import "../assets/Style/meny.css";
import { pizzaKategorier } from "../components/PizzaMenu";
import menyBg from "../assets/images/menyBg/menyPageBg1.jpg";

const Meny = () => {
  const pizzaKategoriNamn = [
    "Pizza klass 1",
    "Pizza klass 2",
    "Pizza klass 3",
    "Pizza klass 4",
    "Oxfilé pizzor",
    "Mexikanska pizzor",
    "Mozzarella pizzor",
    "Fläskfilé pizzor",
    "Sallader",
  ];

  const individuellaPriserKategorier = ["Amerikanska panpizza", "Nya pizzor"];
  const individuellaPriserPizzor = [
    "13. Kj special",
    "73. Black & white",
    "42. Kebab special 2",
  ];

  return (
    <div className="meny-container">
      <img src={menyBg} alt="Meny Page Background" className="meny-page-bg" />
      <h2>Vår Meny</h2>
      <div className="pizza-grupper">
        {Object.entries(pizzaKategorier).map(([kategori, rätter]) => (
          <div key={kategori} className="pizza-grupp">
            <h2 className="pizza-grupp-titel">{kategori}</h2>
            {pizzaKategoriNamn.includes(kategori) &&
              rätter.length > 0 &&
              !individuellaPriserKategorier.includes(kategori) && (
                <div className="pizza-priser">
                  <p>Normal: {rätter[0].pris} kr</p>
                  {rätter[0].barnpizza !== undefined && (
                    <p>Barnpizza: {rätter[0].barnpizza} kr</p>
                  )}
                  {rätter[0].familjepizza && (
                    <p>Familjepizza: {rätter[0].familjepizza} kr</p>
                  )}
                </div>
              )}
            <ul className="pizza-lista">
              {rätter.map((rätt) => (
                <li key={rätt.namn} className="pizza-item">
                  <div className="pizzanamn-ingredienser">
                    <h4>{rätt.namn}</h4>
                    <p>{rätt.ingredienser}</p>
                    {rätt.bild && (
                      <div className="sauce-image-container">
                        <img
                          src={rätt.bild}
                          alt={rätt.namn}
                          className="sauce-image"
                        />
                      </div>
                    )}
                  </div>
                  {(individuellaPriserKategorier.includes(kategori) ||
                    !pizzaKategoriNamn.includes(kategori) ||
                    individuellaPriserPizzor.includes(rätt.namn)) && (
                    <div className="pizzapriser">
                      <p>{rätt.pris} kr</p>
                      {rätt.barnpizza && <p>Barnpizza: {rätt.barnpizza} kr</p>}
                      {rätt.familjepizza && (
                        <p>Familjepizza: {rätt.familjepizza} kr</p>
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Meny;
