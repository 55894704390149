import React from "react";

import "../assets/Style/footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <h1 className="footer-text">
        Designed, Developed & Provided by: Phil the Creative.
      </h1>
    </div>
  );
};

export default Footer;
