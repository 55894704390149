import React from "react";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import "../assets/Style/SocialMediaIcons.css";

const SocialMediaIcons = () => {
  return (
    <div className="social-media-icons">
      <a
        href="https://www.facebook.com/Istrapizzeria"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </a>
      <a
        href="https://www.instagram.com/istra_pizzeria/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
      <a
        href="https://www.tiktok.com/@istra26"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTiktok />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
